<template>

  <div>
    <b-card>
      <AgGrid
        ref="CSQGrid"
        :grid="aggrid"
        @LoadAll="LoadAll"
      >
        <template v-slot:lftbtn>
          <b-button
            v-if="activeUserInfo.userRole !== 'readonly admin' && activeUserInfo.userRole !== 'read'"
            v-b-tooltip.hover="'Query Cargo'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-1"
            @click="OpenCreate()"
          ><font-awesome-icon
             :icon="['far', 'plus-square']"
             class="mr-50"
             size="lg"
           />
            <span class="align-middle">Query</span></b-button>
        </template>

      </AgGrid>

      <b-modal
        id="CreateCargoModal"
        ref="CreateCargo"
        title="New Cargo Status Query"
        size="lg"
        centered
        no-close-on-backdrop
      >
        <div class="demo-inline-spacing my-1">
          <b-form-radio
            v-model="querytype"
            name="Airwaybill"
            value="A"
          >
            Air
          </b-form-radio>
          <b-form-radio
            v-model="querytype"
            name="Oceanbill"
            value="O"
          >
            Ocean/Truck/Rail
          </b-form-radio>
          <b-form-radio
            v-model="querytype"
            disabled
            name="Entries"
            value="E"
          >
            Entries
          </b-form-radio>
          <b-form-radio
            v-model="querytype"
            disabled
            name="InBond"
            value="I"
          >
            In-Bond
          </b-form-radio>
        </div>
        <validation-observer
          ref="CargoRules"
          tag="form"
        >
          <div v-if="querytype == 'A'">
            <b-row
              v-for="(item, index) in items"

              :id="item.id"
              :key="item.id"
              ref="row"
            >
              <b-col md="5">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Air Waybill #"
                    :rules="`${querytype == 'A' ? 'required|max:12' : 'max:12'}`"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label :class="`${querytype == 'A' ? 'required' : ''}`"><i><b>Air Waybill #:</b></i></label>
                      <b-form-input
                        :id="`MasterAirwayBill${item.id}`"
                        v-model="item.MasterAirwayBill"
                        v-uppercase
                        v-trim
                        :state="errors.length > 0 ? false:null"
                        placeholder="Air Waybill #"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="5">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="House Air Waybill #"
                    rules="max:12"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label class=""><i><b>House Air Waybill #:</b></i></label>
                      <b-form-input
                        :id="`HouseAirwayBill${item.id}`"
                        v-model="item.HouseAirwayBill"
                        v-uppercase
                        v-trim
                        :state="errors.length > 0 ? false:null"
                        placeholder="House Air Waybill #"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-button
                  v-if="item.id !== 1"
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  class="mt-0 mt-md-2"
                  @click="removeItem(index)"
                >
                  <feather-icon
                    icon="XIcon"
                    class="mr-25"
                  />
                </b-button>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="4">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  class="btn-sm"
                  variant="primary"
                  @click="repeateAgain"
                >
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-25"
                  />
                  <span>Add New</span>
                </b-button>
              </b-col>
            </b-row>
          </div>
          <div v-if="querytype == 'O'">
            <b-row
              v-for="(item, index) in items"

              :id="item.id"
              :key="item.id"
              ref="row"
            >
              <b-col md="4">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="BOL"
                    :rules="`${querytype == 'O' ? 'required|max:16' : 'max:16'}`"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label :class="`${querytype == 'O' ? 'required' : ''}`"><i><b>BOL:</b></i></label>
                      <b-form-input
                        :id="`BOL${item.id}`"
                        v-model="item.BOL"
                        v-uppercase
                        v-trim
                        :state="errors.length > 0 ? false:null"
                        placeholder="BOL"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="SCAC"
                    :rules="`${querytype == 'O' ? 'max:4' : 'max:4'}`"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label><i><b>SCAC:</b></i></label>
                      <b-form-input
                        :id="`SCAC${item.id}`"
                        v-uppercase
                        :value="`${ CheckNull(item.BOL) ? item.BOL.substring(0, 4) : ''}`"
                        :state="errors.length > 0 ? false:null"
                        disabled
                        placeholder="SCAC"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Bill of Lading #"
                    :rules="`${querytype == 'O' ? 'max:12' : 'max:12'}`"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label><i><b>Bill of Lading:</b></i></label>
                      <b-form-input
                        :id="`BOLNumber${item.id}`"
                        v-uppercase
                        :value="`${ CheckNull(item.BOL) ? item.BOL.substring(4) : ''}`"
                        disabled
                        :state="errors.length > 0 ? false:null"
                        placeholder="Bill of Lading #"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                md="1"
              >
                <label><i>HBOL?:</i></label>
                <b-form-checkbox v-model="item.IsHouseBOL" />
              </b-col>
              <b-col md="2">
                <b-button
                  v-if="item.id !== 1"
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  class="mt-0 mt-md-2"
                  @click="removeItem(index)"
                >
                  <feather-icon
                    icon="XIcon"
                    class="mr-25"
                  />
                </b-button>
              </b-col>

            </b-row>
            <b-row>
              <b-col md="4">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  class="btn-sm"
                  variant="primary"
                  @click="repeateAgain"
                >
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-25"
                  />
                  <span>Add New</span>
                </b-button>
              </b-col>

            </b-row>
            <b-row>
              <b-col md="6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Request Releated Bill"
                    :rules="`${querytype == 'O' ? 'required' : ''}`"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label :class="`${querytype == 'O' ? 'required' : ''}`"><i><b>Request Releated Bill:</b></i></label>
                      <v-select
                        id="RelatedBill"
                        v-model="query.RelatedBill"
                        :options="RelatedBillOptions"
                        label="text"
                        placeholder="Select a type"
                      />
                      <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </div>

          <b-row v-if="querytype == 'E'">
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Entry Filer"
                  :rules="`${querytype == 'E' ? 'required|max:3' : 'max:3'}`"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label :class="`${querytype == 'E' ? 'required' : ''}`"><i><b>Entry Filer:</b></i></label>
                    <b-form-input
                      id="EntryFiler"
                      v-model="query.EntryFiler"
                      v-uppercase
                      :state="errors.length > 0 ? false:null"
                      placeholder="Entry Filer"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Entry #"
                  :rules="`${querytype == 'E' ? 'required|max:9' : 'max:9'}`"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label :class="`${querytype == 'E' ? 'required' : ''}`"><i><b>Entry # (# Only):</b></i></label>
                    <b-form-input
                      id="EntryNumber"
                      v-model="query.EntryNumber"
                      v-uppercase
                      :state="errors.length > 0 ? false:null"
                      placeholder="Entry #"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Request Releated Bill & Entry"
                  :rules="`${querytype == 'E' ? 'required' : ''}`"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label :class="`${querytype == 'E' ? 'required' : ''}`"><i><b>Request Releated Bill & Entry:</b></i></label>
                    <v-select
                      id="RelatedBillEntry"
                      v-model="query.RelatedBill"
                      :options="RelatedBillOptions"
                      label="text"
                      placeholder="Select a type"
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="querytype == 'I'">
            <!--b-col md="6">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="SCAC"
                      :rules="`${querytype == 'I' ? 'max:4' : 'max:4'}`"
                    >
                      <b-form-group
                        :state="errors.length > 0 ? false:null"
                      >
                        <label :class="`${querytype == 'I' ? 'required' : ''}`"><i><b>SCAC:</b></i></label>
                        <b-form-input
                          id="SCAC1"
                          v-model="query.SCAC"
                          v-uppercase
                          :state="errors.length > 0 ? false:null"
                          placeholder="SCAC"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-form-group>
                </b-col -->
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="In Bond #"
                  :rules="`${querytype == 'I' ? 'required|max:12' : 'max:12'}`"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label :class="`${querytype == 'I' ? 'required' : ''}`"><i><b>In Bond #:</b></i></label>
                    <b-form-input
                      id="InBondNumber"
                      v-model="query.InBondNumber"
                      v-uppercase
                      :state="errors.length > 0 ? false:null"
                      placeholder="In Bond #"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row md="12">
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Output Limit"
                  rules="required"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label class="required"><i><b>Output Limit:</b></i></label>
                    <v-select
                      id="OutputLimit"
                      v-model="query.OutputLimit"
                      :options="OutputLimitOptions"
                      label="text"
                      placeholder="Select a Limit"
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              md="6"
            >
              <label
                v-b-tooltip.hover.top="'By subscrbing, you will be automatically getting the latest cargo status.'"
              ><i><b>Subscribe:</b></i></label>
              <b-form-checkbox v-model="query.Subscribe" />
            </b-col>
          </b-row>
        </validation-observer>
        <template #modal-footer>
          <div>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mb-1"
              @click="CreateCargoStatus()"
            >
              <span class="align-middle">Save</span></b-button>
          </div>
        </template>
      </b-modal>

    </b-card>
  </div>
</template>

<script>
import axios from '@axios'

import { required } from '@validations'
import { heightTransition } from '@core/mixins/ui/transition'
import ViewBtnRenderer from '@core/layouts/ViewBtnRenderers/ABI/ABICargoStatusViewBtnRenderer'
import AgGrid from '@/layouts/components/AgGrid.vue'

export default {
  components: {
    AgGrid,
  },
  mixins: [heightTransition],
  data() {
    return {
      required,
      aggrid: [],
      queryid: '',
      querytype: 'A',
      items: [{
        id: 1,
        EntryFiler: '',
        EntryNumber: '',
        InBondNumber: '',
        SCAC: '',
        BOL: '',
        IsHouseBOL: false,
        BOLNumber: '',
        MasterAirwayBill: '',
        HouseAirwayBill: '',
        prevHeight: 0,
      }],
      nextTodoId: 2,
      query: {
        RelatedBill: { text: 'Yes', value: 'Y' },
        OutputLimit: { text: 'All', value: '2' },
        Subscribe: false,
      },
      RelatedBillOptions: [
        { text: 'Yes', value: 'Y' },
        { text: 'No', value: '' },

      ],
      OutputLimitOptions: [
        { text: 'All', value: '2' },
        { text: 'Last 5', value: '1' },
        { text: 'Most Recent', value: '1' },
      ],
    }
  },
  computed: {
    isLoggedIn() { return this.$store.getters.user.isLoggedIn },
    activeUserInfo() {
      return this.$store.state.user.AppActiveUser
    },
  },
  watch: {
    querytype() {
      this.resetitems()
    },
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  beforeMount() {
    axios.defaults.headers.common.DBContext = this.$store.state.user.AppActiveUser.ABIDbContext
    this.aggrid = this.InitGridInstance('CSQ')
    this.aggrid.columnDefs = [
      {
        headerName: '',
        field: 'queryId',
        filter: false,
        sortable: false,
        autoHeight: true,
        width: 120,
        minWidth: 120,
        maxWidth: 120,
        suppressSizeToFit: true,
        suppressMovable: true,
        pinned: 'left',
        lockPinned: true,
        cellRenderer: 'ViewBtnRenderer',
        enableRowGroup: false,
        enablePivot: false,
      },
      {
        headerName: 'Latest Status', field: 'latestStatus', minWidth: 110, filter: 'agSetColumnFilter',
      },
      {
        headerName: 'Master #', field: 'master', minWidth: 175,
      },
      {
        headerName: 'House #', field: 'house', minWidth: 150,
      },
      {
        headerName: 'Last Updated', field: 'lastUpdated', minWidth: 200,
      },
      {
        headerName: 'Query Date', field: 'queryDate', minWidth: 200,
      },
      {
        headerName: 'Query By', field: 'queryBy', minWidth: 200, filter: 'agSetColumnFilter',
      },
    ]
    this.aggrid.gridOptions.onSortChanged = () => { this.$refs.CSQGrid.saveState('CSQ') }
    this.aggrid.gridOptions.onColumnResized = () => { this.$refs.CSQGrid.saveState('CSQ') }
    this.aggrid.gridOptions.onColumnMoved = () => { this.$refs.CSQGrid.saveState('CSQ') }
    this.aggrid.gridOptions.onFilterChanged = () => { this.$refs.CSQGrid.saveState('CSQ') }
    this.aggrid.frameworkComponents = {
      ViewBtnRenderer,
    }
    this.LoadAll()
  },
  methods: {
    Open(id, type, master, subscriptionstatus) {
      this.queryid = id
      if (type === 'A') this.$router.push(`/abi/query/cargostatus/detail/${id}/${type}/${master}/${subscriptionstatus}`)
      else if (type === 'O') this.$router.push(`/abi/query/cargostatus/detail/${id}/${type}/${master}/${subscriptionstatus}`)
    },
    DoubleClickOpenPopup(params) {
      this.queryid = params.data.queryId
      if (params.data.type === 'A') this.$router.push(`/abi/query/cargostatus/detail/${params.data.queryId}/${params.data.type}/${params.data.master}/${params.data.isSubscribe}`)
      else if (params.data.type === 'O') this.$router.push(`/abi/query/cargostatus/detail/${params.data.queryId}/${params.data.type}/${params.data.master}/${params.data.isSubscribe}`)
    },
    LoadAll() {
      Promise.all([this.LoadingStart(), this.LoadCargoStatus()])
        .then(() => {
          this.LoadingEnd()
        })
    },
    LoadCargoStatus() {
      axios.get('/abi/query/cargostatus/all')
        .then(rowData => {
          this.aggrid.rowData = rowData.data
        }).then(() => {
          this.$refs.CSQGrid.ResetColumns()
        })
    },
    resetitems() {
      this.items = [{
        id: 1,
        EntryFiler: '',
        EntryNumber: '',
        InBondNumber: '',
        BOL: '',
        IsHouseBOL: false,
        SCAC: '',
        BOLNumber: '',
        MasterAirwayBill: '',
        HouseAirwayBill: '',
        prevHeight: 0,
      }]
      this.nextTodoId = 2
    },
    OpenCreate() {
      this.resetitems()
      this.$refs.CreateCargo.show()
    },
    CreateCargoStatus() {
      this.$refs.CargoRules.validate().then(result => {
        if (result) {
          this.LoadingStart()

          this.items.forEach(item => {
            let SCAC = ''
            let BOL = ''

            if (this.querytype === 'O' && item.BOL !== null && item.BOL !== '') {
              SCAC = item.BOL.substring(0, 4)
              BOL = item.BOL.substring(4)
            }
            const CargoStatusQueryModel = {
              QueryType: this.querytype,
              IsSubscribe: this.query.Subscribe,
              EntryFiler: this.querytype === 'E' ? item.EntryFiler : '',
              EntryNumber: this.querytype === 'E' ? item.EntryNumber : '',
              InBondNumber: this.querytype === 'I' ? item.InBondNumber : '',
              SCAC: this.querytype === 'O' || this.querytype === 'I' ? SCAC : '',
              BOLNumber: this.querytype === 'O' ? BOL : '',
              IsHouseBOL: this.querytype === 'O' ? item.IsHouseBOL : false,
              MasterAWB: this.querytype === 'A' ? item.MasterAirwayBill.replace('-', '') : '',
              HouseAWB: this.querytype === 'A' ? item.HouseAirwayBill : '',
              // eslint-disable-next-line no-nested-ternary
              RelatedBill: (this.querytype === 'O' || this.querytype === 'E') ? this.CheckNull(this.query.RelatedBill) ? this.query.RelatedBill.value : '' : '',
              OutputLimit: this.query.OutputLimit.value,
            }
            axios.post('/abi/query/cargostatus', CargoStatusQueryModel)
              .then(() => {
                this.LoadingEnd()
                this.$refs.CreateCargo.hide()
                this.DisplaySuccess('Query data has been submitted.')
              }).catch(e => {
                this.LoadingEnd()
                this.$refs.CreateCargo.hide()
                this.DisplayError('Query cannot be saved.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
              })
          })
        } else {
          this.DisplayError('Query cannot be saved.', 'Please check data requirements.')
        }
      })
    },
    Delete(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then(result => {
        if (result.isConfirmed) {
          this.LoadingStart()
          axios.delete(`/abi/query/cargostatus/${id}`).then(() => {
            this.LoadingEnd()
            this.DisplaySuccess('Cargo query has been removed.')
              .then(() => {
                // redirect
                this.LoadCargoStatus()
              })
          }).catch(e => {
            this.LoadingEnd()
            this.DisplayErrorRemove('Cannot remove this query.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
          })
        }
      })
    },
    repeateAgain() {
      this.items.push({
        id: this.nextTodoId += this.nextTodoId,
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.CreateCargo.scrollHeight)
      })
    },
  },
}
</script>
<style lang="scss">
  .repeater-form {
  overflow: hidden;
  transition: .35s height;
}

</style>
